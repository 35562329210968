﻿body {
  background-color: white;

  > .root {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}
